<template>
  <div>
    <div id="app">
      <div class="navbar-Header navbar-mobile bg-black">
        <md-toolbar>
          <div
            v-if="this.checkout == false"
            class="md-toolbar-section-start mt-1"
          >
            <md-button class="md-icon-button" @click="toggleShowNavigation">
              <div class="bar-menu"></div>
              <div class="bar-menu"></div>
              <div class="bar-menu"></div>
            </md-button>
          </div>
          <div v-else class="md-toolbar-section-start shopping-checkout mt-1">
            <md-button class="md-icon-button">
              <div class="bar-menu"></div>
              <div class="bar-menu"></div>
              <div class="bar-menu"></div>
            </md-button>
          </div>

          <div
            class="pl-2 view-home-maquillate__logo md-toolbar-section-center"
          >
            <router-link v-if="!this.getToken" :to="'/'">
              <img
                class="logo-mobile-maquillate"
                src="@/assets/images/logo/logoMaquillateWHITE.png"
                style="
                  margin-left: 12px;
                  width: 220px;
                  margin-top: 4px;
                  margin-top: -5px;
                  image-rendering: -webkit-optimize-contrast;
                "
              />
            </router-link>
            <router-link v-if="this.getToken" :to="'/user/dashboard'">
              <img
                class="logo-mobile-maquillate"
                src="@/assets/images/logo/logoMaquillateWHITE.png"
                style="
                  margin-left: 12px;
                  width: 220px;
                  margin-top: -5px;
                  image-rendering: optimizeQuality;
                "
              />
            </router-link>
          </div>
          <div
            v-if="this.checkout === false"
            class="md-toolbar-section-end view-home-maquillate__logo"
          >
            <span @click="openDialogSearch">
              <i class="fas fa-search" role="button"></i>
            </span>
            <div>
              <!--              <md-dialog-->
              <!--                :md-active='showSearchMobile'-->
              <!--                class='searchMobile search-view-home border-bottom'-->
              <!--                @keyup='items($event)'-->
              <!--                @keyup.enter='submit(selectedEmployee)'-->
              <!--              >-->
              <!--                <i class='fas fa-search'></i>-->
              <!--                <md-autocomplete-->
              <!--                  v-model='selectedEmployee'-->
              <!--                  :md-fuzzy-search='false'-->
              <!--                  :md-open-on-focus='false'-->
              <!--                  :md-options='employees'-->
              <!--                  class='ml-5 container-search'-->
              <!--                  md-dense-->
              <!--                  md-layout='box'-->
              <!--                >-->
              <!--                  <label>¿Qué quieres aprender hoy?</label>-->
              <!--                  <router-link-->
              <!--                    v-slot='{ item, term }'-->
              <!--                    :to="'/explorar/search/' + item"-->
              <!--                    style='text-decoration: none'-->
              <!--                  >-->
              <!--                    <md-highlight-text :md-term='term'-->
              <!--                    >{{ item }}-->
              <!--                    </md-highlight-text>-->
              <!--                  </router-link>-->
              <!--                </md-autocomplete>-->
              <!--                <md-dialog-actions>-->
              <!--                  <md-button class='button-cancel' @click='close()'>-->
              <!--                    <span class='material-icons close'>close</span>-->
              <!--                  </md-button>-->
              <!--                  &lt;!&ndash; <md-button-->
              <!--                    class="button-cancel"-->
              <!--                    @click="showSearchMobile = false"-->
              <!--                  >-->
              <!--                    <span class="material-icons close">close</span>-->
              <!--                  </md-button> &ndash;&gt;-->
              <!--                </md-dialog-actions>-->
              <!--              </md-dialog>-->
            </div>
          </div>
          <div>
            <div v-if="this.checkout === false" class="w-100">
              <!-- <router-link :to="'/cart'">
                <img
                  class="icon-shopping-cart mobile"
                  :src="require(`@/assets/images/logo/shopping-cart.png`)"
                />
                <span class="bubble mobile">{{getCant}}</span>
              </router-link> -->
              <!-- <div id="menu" class=" pl-0 all-categories btnAllCat"> -->
              <div id="menu" class="pl-0 all-categories btnAllCat">
                <md-menu
                  class="button-all-categories"
                  md-direction="bottom-start"
                >
                  <img
                    v-if="!this.getToken"
                    id="menuCart-1"
                    :src="require(`@/assets/images/logo/shopping-cart.svg`)"
                    alt=""
                    class="icon-shopping-cart mobile"
                    md-menu-trigger
                    @click="this.getCartTotalPrice"
                  />
                  <img
                    v-if="this.getToken"
                    id="menuCart"
                    :src="require(`@/assets/images/logo/shopping-cart.svg`)"
                    alt=""
                    class="icon-shopping-cart mobile user"
                    md-menu-trigger
                    @click="this.getCartTotalPrice"
                  />
                  <span class="text-center circle-cantity-cart">{{
                    getCartCount
                  }}</span>

                  <md-menu-content
                    :class="{ 'hidden-categories': !showNavbar }"
                    class="show-categories shopping-cart d-flex justify-content-end"
                  >
                    <div
                      v-if="this.newAdd == 2"
                      class="alert alert-success-shopping-cart"
                      role="alert"
                    >
                      <i
                        class="fas fa-check pr-2"
                        style="color: #155724 !important"
                      ></i>
                      Tu carrito esta vacio
                    </div>
                    <div class="container-fluid px-0">
                      <div v-if="getCart.length" class="row m-0">
                        <div
                          v-for="course in getCart"
                          :key="course.id"
                          class="col-12 px-0 d-flex pb-3"
                        >
                          <div class="col-4 px-0 pointer">
                            <img
                              :src="
                                course.thumbnail_url
                                  ? course.thumbnail_url
                                  : 'https://smoda.elpais.com/wp-content/uploads/2020/03/GettyImages-1057154458-1-591x447.jpg'
                              "
                              alt="preview img"
                            />
                          </div>
                          <div class="col-7 pr-0" style="white-space: initial">
                            <p class="mb-1" style="font-size: 15px">
                              {{ course.name }}
                            </p>
                            <p style="opacity: 0.6; font-size: 13px">
                              ${{ course.price }}
                            </p>
                          </div>

                          <div
                            class="col text-right px-0 mobile-shop-cart"
                            @click="quitar(course.id)"
                          >
                            <i class="far fa-trash-alt"></i>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <h3 class="text-center cart-text-empty-popup">
                          Su carrito está vacío
                        </h3>
                      </div>
                    </div>
                    <div class="border-top">
                      <div class="d-flex justify-content-between py-2">
                        <p class="mb-0" style="font-size: 17px">Total</p>
                        <p class="mb-0" style="font-size: 17px">
                          ${{ this.getCartTotalPrice }} USD
                        </p>
                      </div>
                      <div class="d-flex justify-content-center">
                        <button
                          class="btn btn-maquillate py-2 px-3 w-100"
                          @click="redirectToCartOrCheckout(true)"
                        >
                          tu carrito
                        </button>
                        <button
                          v-if="getCart.length >= 1"
                          class="btn btn-maquillate-buy py-2 px-3 w-100"
                          @click="redirectToCartOrCheckout(false)"
                        >
                          pagar
                        </button>
                        <button
                          v-else
                          class="btn btn-maquillate-buy py-2 px-3 w-100"
                          disabled
                        >
                          pagar
                        </button>
                      </div>
                    </div>
                  </md-menu-content>
                </md-menu>
              </div>
            </div>
            <div v-else class="float-right view-home-maquillate__buttons">
              <router-link to="/cart">
                <button class="btn btn-maquillate p-2">IR A CARRITO</button>
              </router-link>
            </div>
          </div>
        </md-toolbar>
      </div>
    </div>

    <md-drawer :md-active.sync="showNavigation" md-swipeable>
      <md-toolbar
        class="md-transparent justify-content-center"
        md-elevation="0"
        style="color: white; font-weight: 700"
      >
        <router-link
          :to="'/'"
          style="text-decoration: none; color: white; letter-spacing: 0px"
        >
          <img
            src="@/assets/images/logo/logoMaquillateWHITE.png"
            style="
              margin-right: -5px;
              width: 210px;
              margin-top: 4px;
              margin-top: -5px;
            "
          />
        </router-link>
      </md-toolbar>

      <md-list class="list-menu-mobile">
        <md-list-item v-if="this.getToken">
          <router-link
            style="text-decoration: none; color: white; letter-spacing: 0px"
            to="/user/dashboard"
          >
            <span class="md-list-item-text">MI TABLERO</span>
          </router-link>
        </md-list-item>
        <md-list-item v-if="this.getToken">
          <router-link
            style="text-decoration: none; color: white; letter-spacing: 0px"
            to="/user/cursos"
          >
            <span class="md-list-item-text">MIS CURSOS</span>
          </router-link>
        </md-list-item>
        <md-list-item v-if="this.getToken">
          <div @click="myfavorites">
            <span>MIS FAVORITOS</span>
          </div>
        </md-list-item>
        <md-list-item v-if="this.getToken">
          <div @click="myprogress">
            <span class="md-list-item-text">MI PROGRESO</span>
          </div>
        </md-list-item>
        <md-list-item>
          <router-link :to="'/instructores'">
            <span class="md-list-item-text">INSTRUCTORES</span>
          </router-link>
        </md-list-item>
        <md-list-item>
          <span class="md-list-item-text">CATEGORÍAS</span>
        </md-list-item>
        <md-list-item class="mb-2">
          <!-- ****** MOBILE MENU ****** -->

          <ul>
            <li
              v-for="{ id, title, slug } in this.getPublicCategories"
              :key="id"
            >
              <router-link :to="`/explorar/categoria/${slug}`">
                <span class="p-0">
                  {{ title }}
                </span>
              </router-link>
            </li>
          </ul>
        </md-list-item>

        <md-list-item class="mb-3">
          <router-link :to="'/contactanos'">
            <span class="md-list-item-text">CONTÁCTANOS</span>
          </router-link>
        </md-list-item>
        <md-list-item>
          <router-link to="/explorar">
            <button class="btn btn-tree">DESCUBRE</button>
          </router-link>
        </md-list-item>
        <md-list-item v-if="getProfile">
          <div class="w-100" @click="redirectPerfil">
            <button class="btn btn-one-user">CONFIGURACIÓN</button>
          </div>
        </md-list-item>
        <md-list-item v-if="getProfile">
          <div class="w-100" @click="logout">
            <button class="btn btn-two-user">CERRAR SESIÓN</button>
          </div>
        </md-list-item>
        <md-list-item v-if="!getProfile">
          <router-link to="/login">
            <button class="btn btn-one button-see-classes">
              INICIA SESIÓN
            </button>
          </router-link>
        </md-list-item>

        <md-list-item v-if="!getProfile">
          <router-link to="/register">
            <button class="btn btn-two">CREAR CUENTA GRATIS</button>
          </router-link>
        </md-list-item>
      </md-list>
    </md-drawer>
    <dialog-search :is-open="isOpenSearch" @close-modal="closeDialogSearch" />
  </div>
</template>

<script>
import { AUTH_LOGOUT } from '@/store/actions/login';
import { DELETE_COURSE_TO_CART_AND_SAVE } from '@/store/actions/cart';
import { mapGetters } from 'vuex';
import DialogSearch from '../../../Components/DialogSearch.vue';

export default {
  name: 'NavBarMobile',
  components: {
    DialogSearch,
  },
  props: {
    showNavbar: {
      type: Boolean,
      required: true,
    },
    bgColor: {
      type: Boolean,
      required: true,
    },
    showSearchMobile: {
      type: Boolean,
      required: true,
    },
    limitCategories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpenSearch: false,
      totalAmountC: 0,
      newAdd: false,
      checkout: false,
      bgColorNavv: '',
      listCategories: null,
      listInstructors: null,
      //limitCategories: null,
      showNavigation: false,

      //listCourses: null,
      //allCourses: null,
      selectedEmployee: null,
      employees: [],
    };
  },
  computed: {
    ...mapGetters([
      'getShowCart',
      'getCart',
      'getCartCount',
      'getToken',
      'getProfile',
      'getCartTotalPrice',
      'getPublicCategories',
    ]),
  },
  watch: {
    $route() {
      this.menuLateral();
      this.routeUpdate();
      this.routeCheckout();
    },
    getCartCount: function () {
      this.showCartPopUp();
    },
  },
  mounted() {
    this.routeUpdate();
    this.routeCheckout();
    console.log();
  },
  methods: {
    logout() {
      this.$store.dispatch(AUTH_LOGOUT, this.getProfile.id).then(() => {
        window.location.href = '/';
      });
    },
    redirectPerfil() {
      if (this.getProfile.roles[0] === 'admin') {
        this.$router.push('/admin');
      } else if (this.getProfile.roles[0] === 'partner') {
        this.$router.push('/dashboard');
      } else {
        this.$router.push('/user/configuracion/cuenta');
      }
    },
    openDialogSearch() {
      this.isOpenSearch = true;
    },
    closeDialogSearch() {
      this.isOpenSearch = false;
    },
    myprogress() {
      this.$router.push('/user/progreso');
    },
    myfavorites() {
      this.$router.push('/user/favoritos');
    },
    bgNav() {
      //let nav = document.getElementById('navbar-header-color')
      this.bgColorNavv = '';
    },
    bgNav2() {
      //let nav = document.getElementById('navbar-header-color')
      this.bgColorNavv = 'background:#000;';
    },
    routeUpdate() {
      if (this.$route.fullPath === '/' || this.$route.fullPath === '/#') {
        this.bgNav();
      } else {
        this.bgNav2();
      }
    },
    routeCheckout() {
      this.checkout = this.$route.fullPath === '/cart/checkout';
    },
    menuLateral() {
      this.showNavigation = false;
    },
    items() {
      if (this.selectedEmployee.length === 0) {
        this.employees = [];
      } else {
        this.employees = this.list.filter((item) => {
          return item.toLowerCase().startsWith(this.selectedEmployee)
            ? item.toLowerCase().includes(this.selectedEmployee.toLowerCase())
            : '';
        });
      }
    },
    submit(query) {
      if (query !== '') {
        this.$router.replace({ path: '/explorar/search/' + query });
      }
    },
    quitar(course_id) {
      this.$store
        .dispatch(DELETE_COURSE_TO_CART_AND_SAVE, course_id)
        .then(() => {
          this.$notify({
            timeout: 500,
            type: 'success',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Curso eliminado de carrito.',
          });
        })
        .catch((err) => {
          console.log(err);
        });
      //this.guardar();
    },
    redirectToCartOrCheckout(val) {
      if (val) {
        this.$router.push({ path: '/cart' });
      } else {
        this.$router.push({ path: '/cart/checkout' });
      }
    },
    showCartPopUp() {
      const menuCart = document.getElementById('menuCart');
      if (menuCart) {
        if (this.getShowCart > 0) {
          this.newAdd = 1;
          if (this.$route.fullPath !== '/cart') {
            if (this.getCart.length > 0) {
              setTimeout(() => {
                this.newAdd = 3;
              }, 3000);
            } else {
              this.newAdd = 2;
            }
          }
        }
      }
    },
    toggleShowNavigation() {
      this.showNavigation = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.md-drawer.md-theme-default {
  background: #000;
}
.circle-cantity-cart {
  padding: 1px !important;
  width: 20px;
  height: 20px;
  background-color: #b7227e;
  color: white;
  font-size: 11px;
  border-radius: 50px;
  font-weight: bold;
  position: absolute;
  margin-top: -6px;
  margin-left: -20px;
}
</style>
