<template>
  <div class="container-fluid size">
    <div class="row">
      <div class="col desk-logo">
        <img alt="" src="/img/theme/logo-maquillate-blue.png" />
        <p class="info-ssl"><i class="fas fa-lock"></i>ASEGURADO CON SSL</p>
      </div>
      <div class="col pt-3 footer-links">
        <p class="title-footer">¿QUIENES SOMOS?</p>
        <router-link
          :to="{ name: 'Nosotros' }"
          class="text-decoration-none text-uppercase"
        >
          <p>Sobre</p>
        </router-link>
      </div>
      <div class="col pt-3 footer-links">
        <p class="title-footer">CATEGORIAS</p>
        <p v-for="{ id, slug, title } in getPublicCategories" :key="id">
          <router-link :to="`/explorar/categoria/${slug}`">
            <span class="p-0 text-uppercase">
              {{ title }}
            </span>
          </router-link>
        </p>
      </div>
      <div class="col pt-3 footer-links">
        <p class="title-footer">SERVICIO AL CLIENTE</p>

        <p>
          <router-link
            :to="{ name: 'FrequentQuestions' }"
            class="text-decoration-none router-link-text"
          >
            PREGUNTAS FRECUENTES
          </router-link>
        </p>
        <p>
          <router-link
            :to="{ name: 'Contactanos' }"
            class="text-decoration-none text-uppercase"
          >
            Contáctanos
          </router-link>
        </p>
      </div>
      <div class="col pt-3 footer-links">
        <p class="title-footer">SOCIAL</p>
        <p>
          <a
            href="https://www.facebook.com/maquillatecom"
            target="_blank"
            class="text-decoration-none text-uppercase"
            >Facebook</a
          >
        </p>
        <p>
          <a
            href="https://www.instagram.com/maquillate/"
            target="_blank"
            class="text-decoration-none text-uppercase"
            >Instagram</a
          >
        </p>
        <p>
          <a
            href="https://www.tiktok.com/@maquillatecom"
            target="_blank"
            class="text-decoration-none text-uppercase"
            >Tik Tok</a
          >
        </p>
      </div>
      <div class="col-mobile-logo col mt-5 pl-0">
        <img alt="" src="/img/theme/logo-maquillate-blue.png" />
        <p class="info-ssl"><i class="fas fa-lock"></i>ASEGURADO CON SSL</p>
      </div>

      <div class="col-12 mt-5">
        <div class="footer-card text-center footer-box">
          <div>
            <p class="mb-0 footer-elmnt text-uppercase">
              <img
                class="pr-1"
                src="/img/theme/logo-maquillate-blue.png"
                width="30"
              />
              © 2023 MAQUÍLLATE.COM.
            </p>

            <p class="mb-0 footer-elmnt text-uppercase">
              Todos los derechos reservados.
            </p>
          </div>

          <div class="pt-2">
            <router-link
              :to="'/terminos-condiciones'"
              style="text-decoration: none; text-transform: uppercase"
            >
              <p class="mb-0 footer-elmnt text-uppercase">
                Términos de Servicio
              </p>
            </router-link>

            <span class="moon-separator">•</span>

            <router-link
              :to="'/politica-privacidad'"
              style="text-decoration: none; text-transform: uppercase"
            >
              <p>Politica de Privacidad</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FooterHome',
  computed: {
    ...mapGetters(['getPublicCategories']),
  },
};
</script>

<style scoped>
.view-home__part7 .moon-separator {
  color: #b7227e;
  padding: 10px !important;
  vertical-align: baseline;
  font-size: 23px;
  line-height: 0px !important;
}
.md-theme-default a:not(.md-button) {
  color: #000 !important;
}

.view-home__part7 p {
  font-weight: 500;
  font-size: 14px;
  color: #000;
  align-self: center;
}
</style>
