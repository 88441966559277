<template>
  <div class="container-fluid size">
    <div class="row">
      <div class="col desk-logo">
        <img alt="" src="/img/theme/logo-maquillate-blue.png" />
        <p class="info-ssl"><i class="fas fa-lock"></i>ASEGURADO CON SSL</p>
      </div>
      <div class="col pt-3 footer-links">
        <p class="title-footer pb-2">¿QUIENES SOMOS?</p>

        <router-link
          :to="{ name: 'Nosotros' }"
          class="text-decoration-none router-link-text"
        >
          <div class="pbl-3 hover-footer-text">
            <span>SOBRE</span>
          </div>
        </router-link>
      </div>
      <div class="col pt-3 footer-links">
        <p class="title-footer pb-2">CATEGORIAS</p>
        <!-- AJUSTAR LOS ENLACES -->
        <div class="">
          <ul class="category-list">
            <li v-for="{ id, title, slug } in getPublicCategories" :key="id">
              <router-link
                :to="`/explorar/categoria/${slug}`"
                class="text-decoration-none router-link-text pbl-3 mb-link hover-footer-text"
              >
                {{ title.toUpperCase() }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="col pt-3 footer-links">
        <p class="title-footer pb-2">SERVICIO AL CLIENTE</p>

        <div class="pbl-3 hover-footer-text">
          <span>
            <router-link
              :to="{ name: 'FrequentQuestions' }"
              class="text-decoration-none router-link-text"
            >
              PREGUNTAS FRECUENTES
            </router-link>
          </span>
        </div>
        <div class="pbl-3 hover-footer-text">
          <span>
            <router-link
              :to="{ name: 'Contactanos' }"
              class="text-decoration-none router-link-text"
            >
              CONTÁCTANOS
            </router-link>
          </span>
        </div>
      </div>
      <div class="col pt-3 footer-links">
        <p class="title-footer pb-2">SOCIAL</p>
        <div class="pbl-3 hover-footer-text">
          <span>
            <a
              href="https://www.facebook.com/maquillatecom"
              target="_blank"
              class="text-decoration-none router-link-text"
              >FACEBOOK</a
            >
          </span>
        </div>
        <div class="pbl-3 hover-footer-text">
          <span
            ><a
              href="https://www.instagram.com/maquillate/ "
              target="_blank"
              class="text-decoration-none router-link-text"
              >INSTAGRAM</a
            ></span
          >
        </div>
        <div class="pbl-3 hover-footer-text">
          <span
            ><a
              href="https://www.tiktok.com/@maquillatecom"
              target="_blank"
              class="text-decoration-none router-link-text"
              >TIK TOK</a
            ></span
          >
        </div>
      </div>
      <div class="col-mobile-logo col mt-5 pl-0">
        <img alt="" src="/img/theme/logo-maquillate-blue.png" />
        <p class="info-ssl"><i class="fas fa-lock"></i>ASEGURADO CON SSL</p>
      </div>
      <div class="col-12 mt-5">
        <div class="footer-card">
          <p class="mb-0 text-center">
            <img
              class="pr-1"
              src="/img/theme/footer-maquillate.png"
              width="30"
            />
            © 2023 MAQUÍLLATE.COM. TODOS LOS DERECHOS RESERVADOS.
          </p>
          <div class="flex d-inline-flex text-uppercase mr-6 ml-6">
            <p class="text-center pr-2">
              <router-link
                :to="'/terminos-condiciones'"
                style="text-decoration: none"
              >
                <p>Terminos y Condiciones</p>
              </router-link>
            </p>

            <p class="text-center footer-separator">•</p>
            <p class="text-center pl-2">
              <router-link
                :to="'/politica-privacidad'"
                style="text-decoration: none"
              >
                <p>Politica de Privacidad</p>
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FooterShowCourse',
  computed: {
    ...mapGetters(['getPublicCategories']),
  },
};
</script>

<style scoped>
.hover-footer-text:hover {
  font-weight: 600;
}

.footer-links a p,
.footer-links p {
  padding: 0px;
  margin-bottom: 4px;
  text-decoration: none;
}

.router-link-text {
  font-size: 14px;
}

.footer-link {
  text-transform: uppercase !important;
}

.pbl-3:hover {
  background-color: rgba(228, 42, 157, 0.15);
  display: inline-block;
  cursor: pointer;
}

.pbl-3 {
  border-radius: 10px;
  padding: 10px;
  background-color: white;
}

.category-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mb-link {
  display: inline-block;
}
.md-theme-default a:not(.md-button) {
  color: #000 !important;
}
.footer-separator {
  color: #be629d;
  font-size: 24px;
  height: 23px;
  width: 10px;
  position: absolute;
  top: 40%;
  left: 50%;
  right: 50%;
}
</style>
