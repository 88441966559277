<template>
  <md-dialog :md-active="open" class="searchMobile search-view-home">
    <i class="fas fa-search padding-top-search"></i>

    <md-field class="ml-5 container-search remove-input-line">
      <md-input
        v-model="value"
        class="input-search pt-3"
        placeholder="¿Qué quieres aprender hoy?"
        @keydown="fetchSearch"
      />
    </md-field>
    <md-dialog-actions>
      <a class="button-cancel cursor-pointer" @click="close()">
        <span class="material-icons close">close</span>
      </a>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
export default {
  name: 'DialogSearch',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close-modal'],
  data() {
    return {
      value: '',
    };
  },
  computed: {
    open: {
      // getter
      get: function () {
        return this.isOpen;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
  },
  async mounted() {
    await this.fetchSearch('');
    this.loading = false;
  },
  methods: {
    close() {
      this.$emit('close-modal');
      this.value = '';
    },
    fetchSearch(event) {
      if (event.key === 'Enter') {
        this.$router.push(`/explorar/busqueda?q=${this.value}`);
        this.close();
      }
    },
  },
};
</script>

<style scoped>
.md-ripple-link {
  padding: 13px 20px !important;
}

.input-search::placeholder {
  color: black !important;
}

.remove-input-line::after {
  content: none !important;
}

.remove-input-line::before {
  content: none !important;
}

.cursor-pointer {
  cursor: pointer;
  padding-top: 25px;
}

.input-search {
  margin-top: 0px !important;
  margin-left: 0px;
  margin-right: -15px;
  width: 100%;
  height: 3vh;
  padding: 10px;
}

.input-search::selection {
  background: #a4106c;
}

.padding-top-search {
  padding-top: 30px;
}
</style>
