<template>
  <div class="navbar-Header bg-black">
    <div class="container-fluid size view-home-maquillate__header">
      <div class="row">
        <div
          v-if="this.checkout === false"
          class="col-7 view-home-maquillate__logo pr-0 d-flex align-items-center"
        >
          <router-link
            v-if="!this.getToken"
            style="text-decoration: none; color: white; letter-spacing: 0"
            to="/"
          >
            <img
              alt="logo"
              src="@/assets/images/logo/logoMaquillateWHITE.png"
              style="
                margin-right: -7px;
                width: 210px;
                margin-top: -3px;
                margin-left: -9px;
                image-rendering: optimizeQuality;
              "
            />
          </router-link>
          <router-link
            v-if="this.getToken"
            style="text-decoration: none; color: white; letter-spacing: 0"
            to="/user/dashboard"
          >
            <img
              alt="logo"
              src="@/assets/images/logo/logoMaquillateWHITE.png"
              style="
                margin-right: -7px;
                width: 210px;
                margin-top: -3px;
                margin-left: -9px;
              "
            />
          </router-link>
          <div id="menu-0" class="pl-0 all-categories btnAllCat">
            <div class="button-all-categories">
              <button style="color: white; background: transparent">
                TODAS LAS CATEGORÍAS
                <span class="px-1"><i class="fas fa-chevron-down"></i></span>
              </button>
              <div class="categories-menu">
                <div
                  :class="{ 'hidden-categories': !showNavbar }"
                  class="show-categories"
                >
                  <ul class="list-content-categories pt-3">
                    <li
                      v-for="{ id, slug, title } in this.getPublicCategories"
                      :key="id"
                    >
                      <router-link :to="`/explorar/clases/categoria/${slug}`">
                        <span class="p-0">
                          {{ title }}
                        </span>
                      </router-link>
                    </li>
                  </ul>
                  <router-link to="/explorar">
                    <div class="button-discover">
                      <button class="btn">DESCUBRE</button>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <router-link class="mr-2 instructorBtn" :to="{ name: 'Instructors' }">
            <button class="btn w-auto border-0 pl-2 pr-0">INSTRUCTORES</button>
            <div class="container-instructors-menu">
              <div
                v-if="!this.getInstructorOrderByFeatured.length"
                class="menu-instructors"
              >
                No hay instructores.
              </div>
              <div v-else class="menu-instructors">
                <div
                  v-for="instructor in this.getInstructorOrderByFeatured"
                  :key="instructor.id"
                  class="d-flex flex-column align-items-center"
                >
                  <router-link
                    :to="`/instructores/instructor/${
                      instructor.id
                    }/${getInstructorSlug(instructor)}`"
                    style="text-decoration: none"
                  >
                    <img
                      :src="
                        instructor.avatar && instructor.avatar.url
                          ? instructor.avatar.url
                          : ''
                      "
                      class="instructors-populars mx-auto"
                    />
                    <span
                      >{{ instructor.firstname }}
                      {{ instructor.lastname }}</span
                    >
                  </router-link>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div
          v-else
          class="col-7 view-home-maquillate__logo pr-0 d-flex align-items-center"
        >
          <router-link
            style="text-decoration: none; color: white; letter-spacing: 0px"
            to="/"
          >
            <img
              src="@/assets/images/logo/logoMaquillateWHITE.png"
              style="
                margin-right: -7px;
                width: 240px;
                margin-top: 1px;
                margin-left: -9px;
                image-rendering: optimizeQuality;
              "
            />
          </router-link>
        </div>
        <div class="col-5 px-0 pt-1">
          <div
            v-if="!this.checkout"
            class="float-right view-home-maquillate__buttons"
          >
            <div class="searchEvent login-home">
              <md-menu md-direction="bottom-start">
                <span
class="show-courses__search"
@click="openDialogSearch"
                  ><i class="fas fa-search"></i
                ></span>
              </md-menu>
            </div>
            <!-- --codigo input search-- -->
            <router-link v-if="!this.getToken" class="mr-2" to="/login">
              <button class="btn btn-one button-see-classes">
                INICIA SESIÓN
              </button>
            </router-link>

            <router-link v-if="!this.getToken" to="/register">
              <button class="btn btn-two">CREAR CUENTA GRATIS</button>
            </router-link>

            <router-link v-if="this.getToken" class="mr-2" to="/user/progreso">
              <button class="btn show-courses__btn">MI PROGRESO</button>
            </router-link>
            <span v-if="this.getToken" class="ml-2 instructorBtn">
              <button
                class="w-auto p-0"
                style="background: transparent; border: none"
              >
                <span class="avatar avatar-sm rounded-circle">
                  <img :src="getProfile.avatar.url" />
                </span>
              </button>
              <div class="container-instructors-menu">
                <div class="menu-profile d-flex flex-column">
                  <router-link class="link-items" to="/user/dashboard">
                    <div class="dropdown-item first-child">
                      <span>Mi tablero</span>
                    </div>
                  </router-link>
                  <router-link class="link-items" to="/user/cursos">
                    <div class="dropdown-item">
                      <span>Mis cursos</span>
                    </div>
                  </router-link>
                  <router-link class="link-items" to="/user/favoritos">
                    <div class="dropdown-item">
                      <span>Mis favoritos</span>
                    </div>
                  </router-link>
                  <router-link
                    class="link-items"
                    :to="{
                      name: 'UserSettingsView',
                      params: { option: 'cuenta' },
                    }"
                  >
                    <div class="dropdown-item">
                      <span>Configuración</span>
                    </div>
                  </router-link>
                  <router-link
                    class="link-items"
                    :to="{
                      name: 'UserSettingsView',
                      params: { option: 'ayuda' },
                    }"
                  >
                    <div class="dropdown-item">
                      <span>Ayuda</span>
                    </div>
                  </router-link>
                  <div class="dropdown-item last-child" @click="logout">
                    <span>Cerrar sesión</span>
                  </div>
                </div>
              </div>
            </span>
            <div id="menu" class="pl-0 all-categories btnAllCat">
              <md-menu
                class="button-all-categories"
                md-direction="bottom-start"
              >
                <img
                  v-if="!this.getToken"
                  id="menu-1"
                  :src="require(`@/assets/images/logo/shopping-cart.svg`)"
                  alt=""
                  class="icon-shopping-cart"
                  md-menu-trigger
                />
                <img
                  v-if="this.getToken"
                  id="menu-2"
                  :src="require(`@/assets/images/logo/shopping-cart.svg`)"
                  alt=""
                  class="icon-shopping-cart user"
                  md-menu-trigger
                />
                <span v-if="this.getToken" class="bubble user text-center">{{
                  getCartCount
                }}</span>
                <span
                  v-if="!this.getToken"
                  class="text-center circle-cantity-cart"
                  >{{ getCartCount }}</span
                >

                <md-menu-content
                  :class="{ 'hidden-categories': !showNavbar }"
                  class="show-categories shopping-cart d-flex justify-content-end"
                  style="max-width: 15px !important; margin-left: -46px"
                >
                  <div
                    v-if="this.newAdd === 2"
                    class="alert alert-success-shopping-cart"
                    role="alert"
                  >
                    <i
                      class="fas fa-check pr-2"
                      style="color: #155724 !important"
                    ></i>
                    Tu carrito esta vacio
                  </div>
                  <div class="container-fluid px-0">
                    <div v-if="getCart.length" class="row m-0">
                      <div
                        v-for="course in getCart"
                        :key="course.id"
                        class="col-12 px-0 d-flex pb-3"
                      >
                        <div class="col-4 px-0 pointer">
                          <img
                            :src="
                              course.thumbnail_url
                                ? course.thumbnail_url
                                : 'https://smoda.elpais.com/wp-content/uploads/2020/03/GettyImages-1057154458-1-591x447.jpg'
                            "
                            alt="preview img"
                          />
                        </div>
                        <div class="col-7 pr-0" style="white-space: initial">
                          <p class="mb-0" style="font-size: 15px">
                            {{ course.name }}
                          </p>
                          <p style="opacity: 0.6; font-size: 13px">
                            ${{ course.price }}
                          </p>
                        </div>

                        <div
                          class="col text-right px-0"
                          @click="removeCourse(course.id)"
                        >
                          <i class="far fa-trash-alt"></i>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <h3 class="text-center cart-text-empty-popup">
                        Su carrito está vacío
                      </h3>
                    </div>
                  </div>
                  <div class="border-top">
                    <div class="d-flex justify-content-between py-2">
                      <p class="mb-0" style="font-size: 17px">Total</p>
                      <p class="mb-0" style="font-size: 17px">
                        ${{ this.getCartTotalPrice }} USD
                      </p>
                    </div>
                    <div class="d-flex justify-content-center">
                      <button
                        class="btn-maquillate btn-maquillate-cart py-2 px-3 w-100 mr-2"
                        @click="redirectToCartOrCheckout(true)"
                      >
                        tu carrito
                      </button>
                      <button
                        v-if="getCart.length >= 1"
                        class="btn-maquillate-buy py-2 px-3 w-100"
                        @click="redirectToCartOrCheckout(false)"
                      >
                        pagar
                      </button>
                      <button
                        v-else
                        class="btn-maquillate-buy py-2 px-3 w-100"
                        disabled
                      >
                        pagar
                      </button>
                    </div>
                  </div>
                </md-menu-content>
              </md-menu>
            </div>
          </div>
          <div v-else class="float-right view-home-maquillate__buttons">
            <router-link to="/cart">
              <button class="btn btn-two">IR A CARRITO</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <dialog-search :is-open="isOpenSearch" @close-modal="closeDialogSearch" />
  </div>
</template>

<script>
import { AUTH_LOGOUT } from '@/store/actions/login';
import DialogSearch from '../../../Components/DialogSearch.vue';
import { DELETE_COURSE_TO_CART_AND_SAVE } from '@/store/actions/cart';
import { mapGetters } from 'vuex';
import { FETCH_PUBLIC_INSTRUCTORS_FEATURED } from '../../../../store/actions/public';
export default {
  name: 'NavBarNormal',
  components: {
    DialogSearch,
  },
  props: {
    showNavbar: {
      type: Boolean,
      required: true,
    },
    bgColor: {
      type: Boolean,
      required: true,
    },
    showSearchMobile: {
      type: Boolean,
      required: true,
    },
    showNavigation: {
      type: Boolean,
      required: true,
    },
    bgColorNav: {
      type: String,
      default: '',
    },
    getProfile: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpenSearch: false,
      cant: 0,
      newAdd: false,
      checkout: false,
      vm: this,
      bgColorNavv: '',
      //allCourses: null,
      selectedEmployee: null,
      employees: [],
      list: [
        'Belleza',
        'Cabello',
        'Cejas',
        'Curso',
        'Course',
        'Curso profesional',
        'Maquillate course',
        'Maquillaje',
        'Maquillaje profesional',
        'Rostro',
        'Uñas',
      ],
      instructorNav: [],
    };
  },
  computed: {
    ...mapGetters([
      'getShowCart',
      'getCart',
      'getCartCount',
      'getToken',
      'getInstructors',
      'getCartTotalPrice',
      'getPublicCategories',
      'getInstructorOrderByFeatured',
    ]),
  },
  watch: {
    $route() {
      this.routeCheckout();
      this.menuCategories();
    },
    getCartCount: function () {
      this.showCartPopUp();
    },
  },
  async mounted() {
    await this.routeCheckout();
    await this.fetchInstructors();
  },
  methods: {
    async fetchInstructors(
      filter = {
        order_by: 'featured',
      },
    ) {
      await this.$store.dispatch(FETCH_PUBLIC_INSTRUCTORS_FEATURED, filter);
    },
    closeDialogSearch() {
      this.isOpenSearch = false;
    },
    openDialogSearch() {
      this.isOpenSearch = true;
    },
    removeCourse(courseId) {
      this.$store
        .dispatch(DELETE_COURSE_TO_CART_AND_SAVE, courseId)
        .then(() => {
          this.$notify({
            timeout: 500,
            type: 'success',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Curso eliminado de carrito.',
          });
        })
        .catch(() => {
          this.$notify({
            timeout: 500,
            type: 'success',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Tenemos problemas al eliminar el curso del carrito',
          });
        });
    },
    redirectToCartOrCheckout(val) {
      if (val) {
        this.$router.push({ path: '/cart' });
      } else {
        this.$router.push({ path: '/cart/checkout' });
      }
    },
    redirectFav() {
      this.$router.push('/favoritos');
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT, this.getProfile.id).then(() => {
        window.location.href = '/';
      });
    },

    routeCheckout() {
      this.checkout = this.$route.fullPath === '/cart/checkout';
    },
    getInstructorSlug(instructor) {
      const fullname = `${instructor.firstname ?? ''} ${
        instructor.lastname ?? ''
      }`;
      return fullname.toLowerCase().replaceAll(' ', '-');
    },
    menuCategories() {
      const menu = document.getElementsByClassName('.md-theme-default');
      if (menu) {
        document.getElementById('menu').click();
      }
    },
    showCartPopUp() {
      const menuCart = document.getElementById('menuCart');
      if (menuCart) {
        if (this.getShowCart > 0) {
          this.newAdd = 1;
          if (this.$route.fullPath !== '/cart') {
            //console.log('holabro')
            menuCart.click();
            if (this.getCart.length > 0) {
              setTimeout(() => {
                this.newAdd = 3;
                //menuCart.click();
              }, 2000);
            } else {
              this.newAdd = 2;
            }
          }
        }
      }
    },
  },
};
</script>

<style>
.link-items {
  text-decoration: none !important;
  letter-spacing: 0;
}
.menu-profile {
border: none !important;
}

.menu-profile a div span,
.menu-profile div span {
  color: #fff;
  font-size: 14px !important;
  line-height: 1.5 !important ;
}

.instructorBtn .container-instructors-menu {
  padding-top: 23px;
  position: absolute;
  padding-left: 50px;
  display: none;
}

.icon-shopping-cart {
  cursor: pointer;
}

.circle-cantity-cart {
  padding: 1px !important;
  width: 20px;
  height: 20px;
  background-color: #b7227e;
  color: white;
  font-size: 11px;
  border-radius: 50px;
  font-weight: bold;
  position: absolute;
  margin-top: -6px;
  margin-left: -20px;
}

.dropdown-item {
  font-size: 14px !important;
}
.show-courses__search {
  position: absolute;
  margin-right: 60px;
  cursor: pointer;
  margin-top: 0px !important;
  top: 25%;
  align-content: center;
  padding: 0px !important;
}

.btn-maquillate-cart {
  font-size: 13px !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  border-color: transparent !important;
}
.btn-maquillate-buy {
  font-size: 13px !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  border-color: transparent !important;
  letter-spacing: 0.7px !important;
}

.cart-text-empty-popup {
  font-weight: 400;
  padding: 10px 0;
}

.show-categories .list-content-categories {
  overflow-y: auto;
  height: 200px;
}
</style>
